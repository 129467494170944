/// <reference path="../../../typings/libraries.d.ts"/>
(function() {

	interface ISystemAlert {
		excerptData: string,
		contentId: string,
		contentUrl: string
	}

	const widgetsWithSystemAlerts = ['Dashboard', 'DashboardV2'];

	function fetchSystemAlert() {
		return new Promise<ISystemAlert>((resolve, reject) => {
			$.get('/API/Content/GetSystemAlert', function(data) {
				if (!data) { return resolve(null); };
				resolve({
					excerptData: data.excerptData,
					contentId: data.contentId,
					contentUrl: data.contentId ? `/Content/${data.contentId}` : null
				} as ISystemAlert);
			}, "json");
		});
	}

	function refreshSystemAlertUI(alert: ISystemAlert) {



		const systemAlertElement = document.getElementById('system_alert');
		const fakeButton = alert.contentUrl ? `<div class="iris-button iris-button--ghost iris-notification__action-button" aria-hidden="true">
				 <span class="iris-button__icon font-icon-angle-right" data-icon-size="xl"></span>
			</div>` : '';
		const systemAlertTemplate = `<div class="iris-notification iris-notification--baguette iris-notification--bulletin" role="alert">
			    <div class="iris-notification__inner">
			        <div class="iris-notification__message" id="system_alert_content">${alert.excerptData}</div>
			        ${fakeButton}
			    </div>
			</div>`;

		if (alert.contentUrl) {
			const anchorElement = document.createElement('A');
			anchorElement.setAttribute('href', alert.contentUrl);
			anchorElement.innerHTML = systemAlertTemplate;
			systemAlertElement.appendChild(anchorElement);
		} else {
			systemAlertElement.innerHTML = systemAlertTemplate;
		}

		Alkami.Dom.fadeIn(systemAlertElement);
	}

	Alkami.Dom.onDocumentReady(() => {
		const metaHeaderElement = document.getElementById('meta_header');
		const systemAlertCapable = widgetsWithSystemAlerts.find(widgetName => widgetName === window.currentWidgetName);

		if (!metaHeaderElement || !systemAlertCapable) {
			return;
		}

		fetchSystemAlert().then((alert) => {
			if (!alert) { return null; }
			refreshSystemAlertUI(alert);
			return alert;
		});

	});



})();
